<template>
  <b-row>
    <b-col sm="12" md="12" class="mt-1">
      <b-form @submit.prevent>
        <b-card>
          <b-card-body>
            <validation-observer
              #default="{ handleSubmit }"
              ref="refFormObserver"
            >
              <b-form
                @submit.prevent="handleSubmit(onSubmit)"
                @reset.prevent="resetForm"
              >
                <b-row
                  v-for="(item, index) in appData"
                  :id="item.id"
                  :key="index"
                  ref="row"
                >
                  <b-col md="3" sm="12" cols="12">
                    <label> {{ $t("fields.holdername") }}</label>
                    <inputvselect
                      name="holdername"
                      :label="
                        (reldata) =>
                          reldata.name
                      "
                      :valuex="appData[index].holdernameid"
                      keys="key"
                      @updatedata="
                        (val) => updatedetails('holdernameid', index, val)
                      "
                      :options="familymembers"
                      :id="'holdername' + index"
                      :key="'hodlername' + index"
                      validations="required"
                    ></inputvselect>
                  </b-col>
                  <b-col md="3" sm="12" cols="12">
                    <label> {{ $t("fields.wallet") }}</label>
                    <inputselect
                      name="wallet"
                      :label="(reldata) => reldata.name"
                      :valuex="appData[index].wallet"
                      keys="key"
                      @updatedata="
                        (val) => {
                          inputdatamanage('wallet', index, val);
                        }
                      "
                      :options="wallets"
                      :id="'wallet' + index"
                      :key="'wallet' + index"
                      validations="required"
                    ></inputselect>
                  </b-col>

                  <b-col sm="12" md="4">
                    <inputtext
                      name="balance"
                      :tooltip="
                        $t('fields.enter') + ' ' + $t('fields.balance')
                      "
                      :label="$t('fields.balance')"
                      :valuex="appData[index].openingbalance"
                      :id="'balance' + item.id"
                      @updatedata="
                        (val) => {
                          managefloat('openingbalance', index, parseFloat(val));
                          managefloat('balance', index, parseFloat(val));
                        }
                      "
                      validations="integer"
                    >
                    </inputtext>
                  </b-col>
                  <b-col sm="12" md="2">
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="danger"
                      class="mt-2"
                      v-on:click="deletewallet(index)"
                    >
                      <feather-icon icon="TrashIcon" class="mr-25" />
                    </b-button>
                  </b-col>
                  <b-col cols="12">
                    <hr />
                  </b-col>
                </b-row>

                <b-row>
                  <b-col sm="12">
                    <div class="d-flex float-right">
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                        class="mr-1"
                        v-on:click="addwallet"
                      >
                        <feather-icon icon="PlusIcon" class="mr-25" />
                        {{ $t("fields.add") }}
                      </b-button>

                      <b-button
                        v-if="edittype == 1"
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                        class="mr-1"
                        :to="{
                          name: GENAPP_APP_STORE_MODULE_NAME + '-view',
                          params: { id: iddata },
                        }"
                      >
                        <feather-icon icon="EyeIcon" class="mr-25" />
                        {{ $t("fields.view") }}
                      </b-button>
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary                            "
                        class="mr-1"
                        type="submit"
                      >
                        <feather-icon icon="Edit3Icon" class="mr-25" />
                        {{ $t("fields.save") }}
                      </b-button>
                    </div></b-col
                  >
                </b-row>
              </b-form>
              <!--/ form -->
            </validation-observer>
          </b-card-body>
        </b-card>
      </b-form>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BFormDatepicker,
  BForm,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BFormFile,
  BFormInvalidFeedback,
  BButton,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import inputvselect from "@/views/Component/Input/inputvselect.vue";
import inputselect from "@/views/Component/Input/inputselect.vue";
import inputtext from "@/views/Component/Input/inputtext.vue";
import inputtextarea from "@/views/Component/Input/inputtextarea.vue";
import { required, alphaNum } from "@validations";
import flatPickr from "vue-flatpickr-component";
import inputcleave from "@/views/Component/Input/inputcleave.vue";

import { ref, onUnmounted } from "@vue/composition-api";
import router from "@/router";
import store from "@/store";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import StoreModule from "./StoreModule";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";
export default {
  components: {
    BCol,
    BCard,
    BRow,
    BForm,
    flatPickr,
    BCardBody,
    BFormGroup,
    inputselect,
    inputvselect,
    inputtextarea,
    inputtext,
    BFormCheckbox,
    BFormInput,
    BFormFile,
    inputcleave,
    BFormInvalidFeedback,
    BButton,
    ToastificationContent,
    ValidationProvider,
    ValidationObserver,
    BFormDatepicker,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      alphaNum,
    };
  },
  setup(props, { emit }) {
    const GENAPP_APP_STORE_MODULE_NAME = "financialassets-wallet";
    const modulename = "Wallet";
    const modulenamesub = "wallet";
    // Register module
    if (!store.hasModule(GENAPP_APP_STORE_MODULE_NAME))
      store.registerModule(GENAPP_APP_STORE_MODULE_NAME, StoreModule);
    const toast = useToast();
    onUnmounted(() => {
      if (store.hasModule(GENAPP_APP_STORE_MODULE_NAME))
        store.unregisterModule(GENAPP_APP_STORE_MODULE_NAME);
    });
    let today = new Date();
    let Walletexp = new Date();
    Walletexp.setFullYear(Walletexp.getFullYear() + 5);

    let key = 0;
    let edittype = 0;
    let storetype = "addData";
    let iddata = router.currentRoute.params.id;
    if (iddata != undefined) {
      edittype = 1;
      storetype = "editData";
    }
    var xtemplate = {
      id: "",
      holdernameid: "",
      holdername: "",
      asofdate: today.toISOString(),
      wallet: "",
      openingbalance: 0,
      balance: 0,
    };
    const blankAppData = [];
    const appData = ref(JSON.parse(JSON.stringify(blankAppData)));
    const addwallet = () => {
      appData.value.push({
        id: Math.random(),
        holdernameid: "",
        holdername: "",
        asofdate: today.toISOString(),
        wallet: "",
        openingbalance: 0,
        balance: 0,
      });
    };
    const deletewallet = (index) => {
      if (confirm("are you sure you want to delete")) {
        appData.value.splice(index, 1);
      }
    };
    addwallet();
    var tempcatch = 1;
    const onSubmit = () => {
      let temp = appData.value;
      let formData = new FormData();

      var appdatax = [];
      var metadataappdatax = [];
      for (var i = 0; i < temp.length; i++) {
        appdatax.push({
          customvalue: 0,
          customcategory: "",
          finalcategory: temp[i].wallet,
          holdernameid: temp[i].holdernameid,
          holdername: temp[i].holdername,
          wallet: temp[i].wallet,
          name: temp[i].name,
          openingbalance: temp[i].openingbalance,
          balance: temp[i].balance,
          asofdate: temp[i].asofdate,
        });
        metadataappdatax.push({
          customvalue: 0,
          customcategory: "",
          holdername: temp[i].holdername,
          holdernameid: temp[i].holdernameid,
          finalcategory: temp[i].wallet,
          balance: temp[i].balance,
          asofdate: temp[i].asofdate,
        });
      }

      formData.append("data", JSON.stringify(appdatax));
      formData.append("metadata", JSON.stringify(metadataappdatax));

      if (tempcatch) {
        tempcatch = 0;
        store
          .dispatch(
            GENAPP_APP_STORE_MODULE_NAME + "/" + storetype,
            edittype == 0 ? formData : jx
          )
          .then((response) => {
            if (response.data.success) {
              toast({
                component: ToastificationContent,
                props: {
                  title: "Success",
                  text:
                    modulename +
                    (edittype == 1 ? " editted" : " added") +
                    " successfully",
                  icon: "CheckIcon",
                  variant: "success",
                },
              });
              router
                .push({
                  name: GENAPP_APP_STORE_MODULE_NAME,
                  params: {
                    id: response.data.id,
                  },
                })
                .catch(() => {});
            } else if (response.data.success == 0) {
              toast({
                component: ToastificationContent,
                props: {
                  title: "Error",
                  variant: "error",
                },
              });
              router
                .push({
                  name: GENAPP_APP_STORE_MODULE_NAME,
                  params: {
                    id: response.data.id,
                  },
                })
                .catch(() => {});
            }
          })
          .catch((error) => {
            tempcatch = 1;

            if (error.response.status === 404) {
              appData.value = undefined;
              toast({
                component: ToastificationContent,
                props: {
                  title: "Error",
                  text: "Couldnt find " + modulenamesub + " details",
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                },
              });
            }
          });
      }
    };
    const familymembers = ref([]);
    store
      .dispatch(GENAPP_APP_STORE_MODULE_NAME + "/fetchefamilymemberdetails")
      .then((response) => {
        familymembers.value = response.data.data;
      });
    const wallets = ref([]);
    store
      .dispatch(GENAPP_APP_STORE_MODULE_NAME + "/fetchewallets")
      .then((response) => {
        wallets.value = response.data.data;
      });
    const resetappData = () => {
      appData.value = JSON.parse(JSON.stringify(blankAppData));
    };
    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetappData);
    return {
      GENAPP_APP_STORE_MODULE_NAME,
      edittype,
      iddata,
      appData,
      modulename,
      modulenamesub,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
      key,
      familymembers,
      today,
      tempcatch,
      Walletexp,
      deletewallet,
      addwallet,
      wallets,
    };
  },
  mounted() {},
  methods: {
    updatedetails(tit, index, val) {
      if (tit == "holdernameid") {
        this.appData[index].holdernameid = val.id;
        this.appData[index].holdername = val.name;
      }
    },
    managefloat(name, index, val) {
      this.$set(this.appData[index], name, parseFloat(val));
    },
    inputdatamanage(name, index, val) {

      this.$set(this.appData[index], name, val);
       },
    updateValue(value) {
      if (value.target.files[0].size <= 2 * 1048576) {
        this.appData.fileData.file = value.target.files[0];
        this.appData.fileData.name = value.target.files[0].name;
        this.appData.fileData.size = value.target.files[0].size;
        this.appData.fileData.type = value.target.files[0].type;
      } else {
        this.$swal({
          icon: "error",
          title: "File limit Reached!",
          text: "File size must be maximum 2 mb .",
          customClass: {
            confirmButton: "btn btn-success",
          },
        });
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
